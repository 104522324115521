// import { Timestamp } from "firebase/firestore";
import React, { useState, useEffect } from "react";
import QRCode from "react-qr-code";
import { format } from "date-fns";
import debounce from "lodash.debounce";
import { useAuth } from "../../../contexts/AuthContext";
import GenericService from "../../../services/Generic.service";
import { isMobile } from 'react-device-detect';
import { maxGraduacaoDescription } from "../../../utils/GraduacoesUtil";
import GenericVinculadoService from "../../../services/GenericVinculado.service";
import { where } from "firebase/firestore";
import encrypt from "../../../utils/encriptUtil";

// export function hideCredencial() {
//   const modal = document.getElementById("modalCredencial");
//   if (modal) {
//     if (modal.classList !== undefined && modal.classList !== null) {
//       if (modal.classList.contains("show")) modal.classList.remove("show");
//     }
//     modal.setAttribute("aria-hidden", "true");
//     modal.setAttribute("style", "display: none");
//   }
// }

// export function showCredencial() {
//   const modal = document.getElementById("modalCredencial");
//   console.log("modal=", modal);
//   if (modal) {
//     if (modal.classList !== undefined && modal.classList !== null) {
//       if (!modal.classList.contains("show")) modal.classList.add("show");
//     }
//     modal.setAttribute("aria-hidden", "false");
//     modal.setAttribute("style", "display: block");
//   }
// }

export default function VirtualCredencialComponent(props) {
  const { domainDetails } = useAuth();
  const queryParams = new URLSearchParams(window.location.search);
  const [evento, setEvento] = useState(undefined);
  // const [pessoa, setPessoa] = useState(undefined);
  // // const [modalidadesInscrito, setModalidadesInscrito] = useState([]);
  const [configuracoes, setConfiguracoes] = useState(undefined);
  const [contato, setContato] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const extraClassName = "";

  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });

  useEffect(() => {
    if (!isMobile) { // Limite da largura lateral da carterinha virtual
      // if (window.innerWidth !== dimensions.width || window.innerHeight !== dimensions.height) {
      setDimensions({
        height: window.innerHeight * 660 / window.innerWidth,
        width: 660
      });
      // }
    } else {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
    }
  }, []);

  useEffect(() => {
    try {
      const debouncedHandleResize = debounce(function handleResize() {
        // console.log("window.innerWidth=", window.innerWidth);
        // console.logI("isMobile=", isMobile);
        if (!isMobile) { // Limite da largura lateral da carterinha virtual
          // if (window.innerWidth !== dimensions.width || window.innerHeight !== dimensions.height) {
          setDimensions({
            height: window.innerHeight * 660 / window.innerWidth,
            width: 660
          });
          // }
        } else {
          setDimensions({
            height: window.innerHeight,
            width: window.innerWidth
          });
        }
      }, 10);

      window.addEventListener("resize", debouncedHandleResize);

      return (_) => {
        window.removeEventListener("resize", debouncedHandleResize);
      };

    } catch (error) {
      console.log("error", error);
    }
  });

  useEffect(() => {
    if (domainDetails != null && configuracoes === undefined && !loading) {
      setLoading(true);
      try {
        (async () => {
          await GenericService.getOne("entidades", domainDetails.domainId).then(async (retData) => {
            if (retData) {
              // console.log("retData.configuracoes:", retData.configuracoes);
              // console.log("retData.dadosContato:", retData.dadosContato);
              setConfiguracoes(retData.configuracoes);
              setContato(retData.dadosContato);
              // setEndereco(retData.dadosEndereco);
            } else {
              console.log("Nada encontrado!");
            }
          });
        })();
      } catch (err) {
        console.log(err);
      }
    }
  }, [domainDetails]);

  const getEvento = async () => {
    try {
      await GenericService.getOne("eventos", queryParams.get("ide")).then(async (retData) => {
        if (retData) {
          setEvento(retData);
        } else {
          console.log("Nada encontrado!");
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  // const getPessoa = async () => {
  //   try {
  //     await GenericService.getOne("praticantes", queryParams.get("id")).then(async (retData) => {
  //       if (retData) {
  //         setPessoa(retData);
  //       } else {
  //         console.log("Nada encontrado!");
  //       }
  //     });
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  // const getModalidadesInscrito = async () => {
  //   let modalidades = [];
  //   let conditions = [];
  //   let ide = queryParams.get("ide");
  //   let uid = props.uid ? props.uid : queryParams.get("id");
  //   conditions.push(where("idEvento", "==", ide));
  //   conditions.push(where("uid", "==", uid));
  //   conditions.push(where("metadados.active", "==", true));
  //   conditions.push(where("metadados.successorId", "==", ""));
  //   conditions.push(where("credenciado", "==", true));
  //   await GenericVinculadoService.getCollectionByConditions("inscritos", conditions).then(async (retData) => {
  //     if (retData) {
  //       retData[0].forEach((inscrito) => {
  //         if (inscrito.data().modalidade) {
  //           modalidades.push(inscrito.data().modalidade);
  //         }
  //       });
  //       setModalidadesInscrito(modalidades);
  //     } else {
  //       console.log("Nada encontrado!");
  //     }
  //   }).catch((err) => {
  //     console.log(err);
  //   });

  // };

  useEffect(() => {
    // getPessoa();
    // getModalidadesInscrito();
    getEvento();
  }, []);

  const canRender = () => {
    // console.log("configuracoes && contato:", configuracoes !== undefined && contato !== undefined);
    return configuracoes !== undefined && contato !== undefined;
  };

  const convertStringDateToBRDate = (date) => {
    try {
      if (date) {
        // console.log("date=", date);
        // console.log("typeof date=", typeof date);
        if (typeof date === "string" && date.includes("T")) date = date.split("T")[0];
        if (typeof date === "object") date = date.toDate().toISOString().split("T")[0];
        let dateParts = date.split("-");
        return dateParts[2] + "/" + dateParts[1] + "/" + dateParts[0];
      }

    } catch (error) {
      console.log("error", error);
    }
    return "";
  }

  const getIdadeConsiderada = (date) => {
    try {
      if (date) {
        return new Date().getFullYear() - date.toDate().getFullYear();
      }

    } catch (error) {
      console.log("error", error);
    }
    return "";
  }

  const getCompactGraduacao = (graduacao) => {
    try {
      if (graduacao > 10) {
        return "D" + (graduacao - 10);
      } else {
        return "G" + (11 - graduacao);
      }

    } catch (error) {
      console.log("error", error);
    }
    return "";
  };

  // logoMargin = () => {
  //   console.log("window.location.href=", window.location.href);
  //   // return window.location.href.includes("/validacao/carteira") ? "451px" : "301px";
  //   return window.location.href.includes("/validacao/carteira") ? "115px" : "115px";
  // };

  // const assinaturaMargin = () => {
  //   return window.location.href.includes("/validacao/carteira") ? "469px" : "339px";
  // };

  // const assinaturaTransform = () => {
  //   return window.location.href.includes("/validacao/carteira") ? "translateX(3px) translateY(-2px)" : "translateY(-2px)";
  // };

  const getUrlValidacao = () => {
    let actualQRCodeData = {
      ide: queryParams.get("ide"),
      uid: props.uid,
      // name: props.pessoa.dadosPessoais.name,
      // graduacao: props.pessoa.dadosTaekwondo ? props.pessoa.dadosTaekwondo.MaxGraduacao ? props.pessoa.dadosTaekwondo.MaxGraduacao.toUpperCase() : "" : "",
      // birthdate: convertStringDateToBRDate(props.pessoa.dadosPessoais.birthdate),
      // cbtkd: `${getCompactGraduacao(props.pessoa.dadosTaekwondo.maxGraduacao)}-${props.pessoa.matriculaCBTKD}`,
      // instituicao: props.pessoa.dadosFiliacao.vinculadoAEntidade ? props.pessoa.dadosFiliacao.vinculadoAEntidade.toUpperCase() : "",
      // mestre: props.pessoa.dadosTaekwondo.mestre ? props.pessoa.dadosTaekwondo.mestre.toUpperCase() : "",
      // kukkiwon: props.pessoa.dadosTaekwondo.kukkiwon,
      // gms: props.pessoa.dadosTaekwondo.registroWT,
      // idade: getIdadeConsiderada(props.pessoa.dadosPessoais.birthdate),
      // modalidades: props.modalidadesInscrito.join(",")
    }
    actualQRCodeData = JSON.stringify(actualQRCodeData);
    actualQRCodeData = encrypt(actualQRCodeData, process.env.REACT_APP_CREDENTIAL_KEY);
    return actualQRCodeData;
    // if (actualUrl.includes("/sie/cadastros/addpraticante")) {
    //   return actualUrl.replace("/sie/cadastros/addpraticante", "/validacao/carteira");
    // }
    // if (actualUrl.includes("/carterinha-virtual")) {
    //   return actualUrl.split("/carterinha-virtual")[0] + "/validacao/carteira?id=" + props.uid;
    // }
    // return actualUrl.split("/sie")[0] + "/validacao/carteira?id=" + props.uid;
  }

  // const getGraduacaoRegistro = (graduacao, index) => {
  //   try {
  //     if (graduacao.data === undefined) {
  //       if (typeof graduacao === "string") {
  //         if (graduacao.includes("-")) {
  //           let graduacaoParts = graduacao.split("-");
  //           if (graduacaoParts.length === 3) {
  //             if (graduacaoParts[0].length === 4) {
  //               return maxGraduacaoDescription(Number(index + 1)) + " - " + graduacaoParts[2] + "/" + graduacaoParts[1] + "/" + graduacaoParts[0];
  //             }
  //             else if (graduacaoParts[2].length === 4) {
  //               return maxGraduacaoDescription(Number(index + 1)) + " - " + graduacaoParts[0] + "/" + graduacaoParts[1] + "/" + graduacaoParts[2];
  //             }
  //           }
  //         }
  //         return (graduacao !== "" ? maxGraduacaoDescription(Number(index + 1)) + " - " + graduacao : "");
  //       }
  //       if (graduacao.toDate !== undefined) {
  //         return (graduacao !== "" ? maxGraduacaoDescription(Number(index + 1)) + " - " + format(graduacao.toDate(), "dd/MM/yyyy") : "");
  //       }
  //       else return "";
  //     }
  //     else if (typeof graduacao.data === "string") {
  //       if (graduacao.aprovacaoConfederacao === true) {
  //         if (graduacao.data.includes("-")) {
  //           let graduacaoParts = graduacao.data.split("-");
  //           if (graduacaoParts.length === 3) {
  //             if (graduacaoParts[0].length === 4) {
  //               return maxGraduacaoDescription(Number(index + 1)) + " - " + graduacaoParts[2] + "/" + graduacaoParts[1] + "/" + graduacaoParts[0];
  //             }
  //             else if (graduacaoParts[2].length === 4) {
  //               return maxGraduacaoDescription(Number(index + 1)) + " - " + graduacaoParts[0] + "/" + graduacaoParts[1] + "/" + graduacaoParts[2];
  //             }
  //           }
  //         }
  //       }
  //       return (graduacao.data !== "" ? maxGraduacaoDescription(Number(index + 1)) + " - " + graduacao.data : "");
  //     }
  //     else if (graduacao.data.toDate !== undefined) {
  //       if (graduacao.aprovacaoConfederacao === true) {
  //         return (graduacao !== "" ? maxGraduacaoDescription(Number(index + 1)) + " - " + format(graduacao.data.toDate(), "dd/MM/yyyy") : "");
  //         // return (graduacao.data !== "" ? maxGraduacaoDescription(Number(index + 1)) + " - " + format(graduacao.data.toDate(), "dd/MM/yyyy") : "");
  //       }
  //     }
  //     else {
  //       return "";
  //     }

  //   } catch (error) {
  //     console.log("error", error);
  //     return "";
  //   }
  // }

  const getScale = () => {
    if (dimensions.width < 576)
      return (dimensions.width) / 337;
    return (dimensions.width - 12) / 337;
  };

  const getMarginLeft = () => {
    return 0;
    // if (dimensions.width <= 1074) return -12;
    // return 0;
    // if (dimensions.width < 576)
    //   return (dimensions.width + 12 - 337) / 2 - 6;
    // return (dimensions.width - 337) / 2 - 6;
  };

  const getMarginTop = () => {
    if (props.noModal) return 58;
    // return 0;
    if (dimensions.width < 576)
      return (dimensions.width + 12 - 337) / 2 + (465 - 337) * getScale() / 2 - 50;
    return (dimensions.width - 337) / 2 + (465 - 337) * getScale() / 2 - 50 - 21;
  };

  const getMinHeight = () => {
    return getMarginTop() + 408 * getScale();
  };

  // console.log("dimensions.width=", dimensions.width);
  // console.log("printCarteirinhaComponent => render() => pessoa=", pessoa);
  if (props.pessoa === undefined) {
    // console.log("pessoa.dadosTaekwondo === undefined");
    return (<div>1. Não foi possível carregar as informações desejadas</div>);
  }
  else if (props.pessoa && props.pessoa.dadosTaekwondo === undefined) {
    // console.log("pessoa.dadosTaekwondo === undefined");
    return (<div>2. Não foi possível carregar as informações desejadas</div>);
  }
  else if (!canRender()) {
    // console.log("canRender()=", canRender());
    return (<div>Carregando...</div>);
  }
  else
    // console.log("window.location.href=", window.location.href);
    // console.log("pessoa:", pessoa);
    // console.log("props.uid:", props.uid);
    // console.log("pessoa.dadosPessoais.birthdate=", props.pessoa.dadosPessoais.birthdate);
    // console.log("state.certificado=", state.certificado ? state.certificado : "undefined");

    try {
      // console.log("VirtualCredencialComponent => render() => pessoa=", pessoa);
      return (
        <>
          {/* <div className="modal fade" id="modalCredencial" tabIndex="-1" role="dialog" aria-labelledby="modalCarteirinhaLabel" aria-hidden="false" show={true}>
            <div className="modal-dialog">
              <div className="modal-content"> */}

          {/* <div className="position-relative"> */}
          <div className="d-flex justify-content-center w-100" style={{ minWidth: "100vv", maxWidth: "100vv", minHeight: `${getMinHeight()}` }}>
            <div className="d-flex flex-column flex-wrap" style={{ marginLeft: `${getMarginLeft()}px`, marginTop: `${getMarginTop()}px`, scale: `${getScale()}` }}>
              {/* <div className="d-flex flex-column flex-wrap" style={{ marginLeft: "0px", marginTop: "0px", scale: "calc(100vv / 337)" }}> */}
              <div
                className="p-0 ms-0 mt-0 mb-0 me-0 position-relative"
                style={{
                  minHeight: "189px", maxHeight: "189px", minWidth: "337px", maxWidth: "337px",
                  backgroundImage: props.arteCarteririnhaIdentificacaoFrente ? props.arteCarteririnhaIdentificacaoFrente : "url(https://firebasestorage.googleapis.com/v0/b/sg-cbtkd.appspot.com/o/modeloscarteirinhas%2FcarteirinhaCBTKDfrente.jpg?alt=media&token=439e3087-a381-4bc4-bc50-63f4b7444961)",
                  backgroundRepeat: "no-repeat",
                  // backgroundColor: props.noBackground ? "white" : "white",
                  backgroundSize: "cover",
                  fontSize: "0.6rem",
                  letterSpacing: "-0.02rem",
                }}
              >
                <div className={props.hideClose ? "d-none" : "position-absolute d-inline-flex justify-content-end w-100"} style={{ paddingTop: "10px", zIndex: "3000", top: "-4px", right: "-4px" }}>
                  <button type="button" className="position-relative btn btn-sm btn-secondary rounded-pill me-1" style={{ padding: "0px 1px 0px 1px", lineHeight: "12px", right: "0px", scale: "0.875" }} onClick={() => props.hideThis()} data-bs-dismiss="modal" aria-label="Close">
                    <span className="material-symbols-outlined" title="Fechar esta credencial">
                      close
                    </span>
                  </button>
                </div>

                <div className="position-absolute">
                  <div className={`text-left col text-black mb-4 ${extraClassName}`}
                    style={{
                      marginLeft: "6px", marginTop: "30px"
                      // , minWidth: "210px", maxWidth: "210px" 
                    }}
                  >
                    {(props.pessoa.dadosPessoais.urlImagemID === undefined || props.pessoa.dadosPessoais.urlImagemID === "") ? (
                      <span className="material-icons-outlined img-thumbnail rounded" style={{ fontSize: "4.04rem", maxHeight: "150px", minHeight: "150px", maxWidth: "114.79px", minWidth: "114.79px" }}>
                        {/* account_circle */}
                      </span>
                    ) : (
                      <img
                        src={props.pessoa.dadosPessoais.urlImagemID}
                        className="img-thumbnail rounded"
                        alt=""
                        style={{
                          maxHeight: "150px"
                          // , maxWidth: "75px" 
                        }}
                      />
                    )}
                  </div>
                </div>

                <div className="position-absolute">
                  <div className={`text-left col text-black mb-4 w-100 ${extraClassName}`}
                    style={{ marginLeft: "134px", marginTop: "45px", minWidth: "200px", maxWidth: "200px" }}>
                    <div style={{ fontSize: "0.45rem", color: "white", textAlign: "left" }}>NOME / NAME</div>
                    <div style={{ transform: "translateY(-3px)", color: "white", textAlign: "left" }}>{props.pessoa.dadosPessoais.name.toUpperCase()}</div>
                  </div>
                </div>

                <div className="position-absolute">
                  <div className={`text-left col text-black mb-4 w-100 ${extraClassName}`}
                    style={{ marginLeft: "134px", marginTop: "67px", minWidth: "210px", maxWidth: "210px" }}>
                    <div style={{ fontSize: "0.45rem", color: "white", textAlign: "left" }}>{props.pessoa.dadosTaekwondo.maxGraduacao > 10 ? "FAIXA PRETA / BLACK BELT" : "FAIXA COLORIDA / COLORED BELT"}</div>
                    <div style={{ transform: "translateY(-3px)", color: "white", textAlign: "left" }}>{props.pessoa.dadosTaekwondo ? props.pessoa.dadosTaekwondo.MaxGraduacao ? props.pessoa.dadosTaekwondo.MaxGraduacao.toUpperCase() : "" : ""}</div>
                  </div>
                </div>

                <div className="position-absolute">
                  <div className={`text-left col text-black mb-4 w-100 ${extraClassName}`}
                    style={{ marginLeft: "134px", marginTop: "89px", minWidth: "210px", maxWidth: "210px" }}>
                    <div style={{ fontSize: "0.45rem", color: "white", textAlign: "left" }}>DATA DE NASCIMENTO / DATE OF BIRTH</div>
                    <div style={{ transform: "translateY(-3px)", color: "white", textAlign: "left" }}>{convertStringDateToBRDate(props.pessoa.dadosPessoais.birthdate)}</div>
                  </div>
                </div>

                <div className="position-absolute">
                  <div className={`text-left col text-black mb-4 w-100 ${extraClassName}`}
                    style={{ marginLeft: "134px", marginTop: "112px", minWidth: "210px", maxWidth: "210px" }}>
                    <div style={{ fontSize: "0.45rem", color: "white", textAlign: "left" }}>CBTKD Nº / ID No.</div>
                    <div style={{ transform: "translateY(-3px)", color: "white", textAlign: "left" }}>{`${getCompactGraduacao(props.pessoa.dadosTaekwondo.maxGraduacao)}-${props.pessoa.matriculaCBTKD}`}</div>
                  </div>
                </div>
              </div>
              <div
                className="p-0 mt-0 mb-0 me-0 position-relative"
                style={{
                  marginLeft: "0px",
                  minHeight: props.compact ? "188px" : "368px",
                  maxHeight: props.compact ? "188px" : "368px",
                  minWidth: "337px", maxWidth: "337px",
                  // backgroundImage: props.arteCarteririnhaIdentificacaoVerso ? props.arteCarteririnhaIdentificacaoVerso : "url(https://firebasestorage.googleapis.com/v0/b/sg-cbtkd.appspot.com/o/modeloscarteirinhas%2FcarteirinhaCBTKDverso_v2.jpg?alt=media&token=e86925fd-ae3f-49f0-a8d9-0900d520264e)",
                  backgroundImage: "url(https://firebasestorage.googleapis.com/v0/b/sg-cbtkd.appspot.com/o/modeloscarteirinhas%2FcarteirinhaVirtualCBTKDversoFinal.jpg?alt=media&token=63746eef-668c-4d93-b454-fd7a50e28a1e)",
                  backgroundRepeat: "no-repeat",
                  backgroundColor: props.noBackground ? "white" : "white",
                  backgroundSize: "cover",
                  fontSize: "0.6rem",
                  letterSpacing: "-0.02rem",
                }}
              >

                <div className="position-absolute d-flex">
                  <div className={`text-start col text-white w-100 ${extraClassName}`}
                    style={{ marginLeft: "6px", marginTop: "12px", minWidth: "334px", maxWidth: "334px" }}>
                    <div className={props.compact ? "d-none" : "d-flex flex-row flex-wrap"} style={{ fontSize: "0.6rem" }}>
                      <div className="d-flex flex-column col-5 align-self-start" style={{ maxWidth: "38%" }}>
                        {props.pessoa.dadosFiliacao.vinculadoAEntidade && (
                          <div className="d-flex flex-column col">
                            <div style={{ fontSize: "0.45rem", color: "white" }}>INSTITUIÇÃO / INSTITUITION</div>
                            <div className="text-break" style={{ transform: "translateY(-3px)" }}>{props.pessoa.dadosFiliacao.vinculadoAEntidade ? props.pessoa.dadosFiliacao.vinculadoAEntidade.toUpperCase() : ""}</div>
                          </div>
                        )}
                        {props.pessoa.dadosTaekwondo && (
                          <div className="d-flex flex-column col">
                            <div style={{ fontSize: "0.45rem", color: "white" }}>MESTRE / MASTER</div>
                            <div className="text-break" style={{ transform: "translateY(-3px)" }}>{props.pessoa.dadosTaekwondo.mestre ? props.pessoa.dadosTaekwondo.mestre.toUpperCase() : ""}</div>
                          </div>
                        )}
                        {props.pessoa.dadosTaekwondo && props.pessoa.dadosTaekwondo.kukkiwon !== "" && (
                          <div className="d-flex flex-column col">
                            <div style={{ fontSize: "0.45rem", color: "white" }}>KUKKIWON Nº / ID No.</div>
                            <div className="text-break" style={{ transform: "translateY(-3px)" }}>{props.pessoa.dadosTaekwondo.kukkiwon}</div>
                          </div>
                        )}
                        {props.pessoa.dadosTaekwondo && props.pessoa.dadosTaekwondo.registroWT !== "" && (
                          <div className="d-flex flex-column col">
                            <div style={{ fontSize: "0.45rem", color: "white" }}>CÓDIGO GMS / GAL Code</div>
                            <div className="text-break" style={{ transform: "translateY(-3px)" }}>{props.pessoa.dadosTaekwondo.registroWT}</div>
                          </div>
                        )}
                        <div className="d-flex flex-column col">
                          <div style={{ fontSize: "0.45rem", color: "white" }}>IDADE / AGE</div>
                          <div className="text-break" style={{ transform: "translateY(-3px)" }}>{getIdadeConsiderada(props.pessoa.dadosPessoais.birthdate)}</div>
                        </div>
                      </div>

                      <div className="d-flex flex-column col align-self-center me-4 pe-4">
                        <div className="d-flex align-items-center align-self-center">

                          {/* <div className="position-absolute"> */}
                          <div className={`text-center text-black col-auto mb-1`}
                            style={{
                              // marginLeft: "273px", marginTop: "124px", 
                              // minWidth: "57px", maxWidth: "57px"
                            }}
                          >
                            <div className="img-thumbnail rounded">
                              <QRCode value={getUrlValidacao()} size={133} />
                            </div>
                          </div>
                          {/* </div> */}

                        </div>
                        <div className="d-flex flex-wrap text-white p-0 mt-1 col align-self-center text-center" style={{ fontSize: "0.45rem" }}>
                          Leia este QR code para autenticar esta credencial<br />Scan this QR code to authenticate this credential
                        </div>
                      </div>


                    </div>
                    <div className="d-flex align-items-center justify-content-center col-12">
                      <div className="d-flex flex-column col align-self-start">
                        <div className="d-flex flex-row col align-items-center gap-2" style={{ width: "97%" }}>
                          <div className="col"><hr /></div>
                          <div className="text-start" style={{ fontSize: "0.875rem", textOverflow: "ellipsis" }}>
                            {evento && evento.dadosEvento && evento.dadosEvento.name.toUpperCase()}
                          </div>
                          <div className="col"><hr /></div>
                        </div>
                        <div className="d-flex flex-row flex-nowrap col-12 gap-0">
                          <div className="d-flex flex-column col align-self-end">
                            <div className={`text-center col ${extraClassName} d-flex column flex-wrap me-4`}>
                              <div className="w-100 mb-2">
                                {(props.pessoa.dadosPessoais.sexo === "Feminino") ? "INSCRITA NAS SEGUINTES MODALIDADES" : "INSCRITO NAS SEGUINTES MODALIDADES"}
                              </div>
                              {props.modalidadesInscrito && props.modalidadesInscrito.map((modalidade, index) => (
                                <div className="w-100" key={`modalidade_${index}`}>
                                  {modalidade}
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="d-flex flex-row flex-wrap p-0 col mt-2 text-white align-items-center fixed-bottom"
                      //  border border-top-1 border-end-0 border-bottom-0 border-start-0"
                      style={{ fontSize: "0.45rem", marginLeft: "2%", width: "96%", borderTopStyle: "solid", borderTopWidth: "1px", borderTopColor: "rgb(85,85,85)" }}>
                      <div className="d-flex align-items-center justify-content-start gap-3 col-4" style={{ fontSize: "0.45rem", scale: "0.5", maxWidth: "170px" }}>
                        {contato.perfilFacebook.length > 0 && (
                          <a href={contato.perfilFacebook} target="_blank" rel="noreferrer">
                            <img src="/assets/images/facebook.png" alt="Facebook" style={{ width: "30px" }} />
                          </a>
                        )}
                        {contato.perfilInstagram.length > 0 && (
                          <a href={contato.perfilInstagram} target="_blank" rel="noreferrer">
                            <img src="/assets/images/instagram.png" alt="Instagram" style={{ width: "30px" }} />
                          </a>
                        )}
                        {contato.perfilTwitter.length > 0 && (
                          <a href={contato.perfilTwitter} target="_blank" rel="noreferrer">
                            <img src="/assets/images/twitter.png" alt="Twitter" style={{ width: "30px" }} />
                          </a>
                        )}
                        {contato.perfilLinkedIn && contato.perfilLinkedIn.length > 0 && (
                          <a href={contato.perfilLinkedIn} target="_blank" rel="noreferrer">
                            <img src="/assets/images/linkedin_black.png" alt="LinkedIn" style={{ width: "30px" }} />
                          </a>
                        )}
                        {contato.perfilYouTube.length > 0 && (
                          <a href={contato.perfilYouTube} target="_blank" rel="noreferrer">
                            <img src="/assets/images/youtube.png" alt="Youtube" style={{ width: "30px" }} />
                          </a>
                        )}
                      </div>
                      <div className="col pe-2 text-nowrap text-end">{`Copyright © 2022-${new Date().getFullYear()}. Todos os direitos reservados`}</div>
                    </div>
                  </div>
                </div>

              </div >

            </div>
          </div >
          {/* </div> */}
          {/* </div>
            </div>
          </div> */}
        </>
      );

    } catch (error) {
      console.log("error", error);
    }
}
