import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/SmartSelect.css";
class SmartSelect extends React.Component {
  constructor(props) {
    super(props);
    this.focusMethod = this.focusMethod.bind(this);
  }

  focusMethod = function getFocus() {
    document.getElementById(this.props.id).focus();
  };

  classNameWithRequiredDecoration = () => {
    if (this.props.required && !this.props.disabled) {
      return "g-select g-select-required-border";
    } else {
      return "g-select";
    }
  };

  // isPresentInCollection = () => {
  //   // //console.log("collection=", this.props.collection, "id=", this.props.value);
  //   if (this.props.collection === undefined) {
  //     return true;
  //   } else if (this.props.collection && this.props.value) {
  //     const found = this.props.collection.find((element) => element.id === this.props.value);
  //     return found ? true : false;
  //   } else {
  //     return false;
  //   }
  // }

  isPresentInCollection = () => {
    // console.log("this.props.isPresentInCollection=", this.props.isPresentInCollection);
    if (this.props.children === undefined) return true;
    if (this.props.children.length && this.props.children[this.props.children.length - 1].length === 0) return true;
    if (this.props.isPresentInCollection === undefined) return true;
    // if (typeof this.props.isPresentInCollection === 'function') {
    //   //console.log("this.props.isPresentInCollection() is function");
    // //console.log("this.props.isPresentInCollection()=", this.props.isPresentInCollection);
    return this.props.isPresentInCollection;
    // }
  }

  render() {
    return (
      <>
        <div className={this.classNameWithRequiredDecoration()}>
          {this.props.showAsBadgeOnDisabled && this.props.disabled ? (
            <>
              <span className="badge bg-secondary w-100">{this.props.value}</span>
            </>
          ) : (
            <>
              <select
                key={this.props.id}
                id={this.props.id}
                value={this.props.value}
                onBlur={this.props.onBlur}
                onChange={this.props.onChange}
                type={this.props.type}
                className={this.props.disabled ? `${this.props.className} disabled-select-arrow` : `${this.props.className}`}
                autoComplete={this.props.autoComplete}
                defaultValue={this.props.defaultValue ? this.props.defaultValue : ""}
                placeholder="&nbsp;"
                // required
                maxLength={this.props.maxLength}
                disabled={this.props.disabled ? this.props.disabled : false}
                style={this.props.style ? this.props.style : {}}
              >
                {/* {console.log(this.props.id, "this.props.children=", this.props.children)} */}
                {this.props.children.length && (this.props.children[this.props.children.length - 1].length === 0)
                  && ((!this.props.doNotShowOnDisabled && this.props.disabled)
                    || (!this.props.disabled))
                  && (<option key={`${this.props.id}-carregadno`} value="">Carregando...</option>)}
                {this.props.children}
                {/* style={{ backgroundImage: "none" }} */}
              </select>
              {this.props.placeholder && (
                <label onClick={this.focusMethod}>
                  {this.props.placeholder} {this.props.required && !this.props.disabled ? "*" : ""}
                </label>
              )}
              {/* {console.log("this.props.collection=", this.props.collection)} */}
              {/* {console.log("this.props.isPresentInCollection=", this.props.isPresentInCollection, "this.props.alternativeValue=", this.props.alternativeValue)} */}
              {!this.isPresentInCollection() && this.props.required && !this.props.disabled && (
                <span className="badge bg-warning text-muted w-100 d-flex flex-wrap justify-content-start">{"Valor não localizado: '"}{this.props.alternativeValue ? this.props.alternativeValue : this.props.value !== "" ? this.props.value : "EM BRANCO"}{"'. Corrija esta relação selecionando o dado na lista acima."}</span>)}
            </>
          )}
        </div>
      </>
    );
  }
}

export default SmartSelect;
