// import cryptoJs from 'crypto-js';
import AES from 'crypto-js/aes';
import cryptoJs from 'crypto-js';

const encrypt = (sharedSecret, key) => {
  // const crypto = require('crypto');
  // console.log("sharedSecret:", sharedSecret);

  // // Encrypt
  // // var ciphertext = cryptoJs.AES.encrypt('my message', key).toString();
  // var ciphertext = cryptoJs.AES.encrypt(sharedSecret, key).toString();

  // // Decrypt
  // var bytes  = cryptoJs.AES.decrypt(ciphertext, key);
  // var originalText = bytes.toString(cryptoJs.enc.Utf8);

  // console.log("originalText:", originalText); // 'my message'


  let output = cryptoJs.AES.encrypt(sharedSecret, key).toString();
  // console.log("Teste: ", output);

  // let decryptedOutput = AES.decrypt(output, key).toString(cryptoJs.enc.Utf8);
  // let decryptedOutput = cryptoJs.AES.decrypt(output, key).toString(cryptoJs.enc.Utf8);
  // let decryptedOutput = cryptoJs.AES.decrypt(output, key);
  // console.log("decryptedOutput:", decryptedOutput);

  return output;
  // const algorithm = 'aes-256-cbc';
  // const iv = cryptoJs.randomBytes(16);
  // let cipher = cryptoJs.createCipheriv('aes-256-cbc', Buffer.from(key), iv);

  // let encrypted = cipher.update(sharedSecret);
  // encrypted = Buffer.concat([encrypted, cipher.final()]);
  // var output = { iv: iv.toString('hex'), encryptedData: encrypted.toString('hex') };
  // return output;
};

export const decript = (ciphertext, key) => {
  // console.log("ciphertext:", ciphertext);
  var bytes = cryptoJs.AES.decrypt(ciphertext, key);
  var originalText = bytes.toString(cryptoJs.enc.Utf8);

  // console.log("originalText:", originalText); // 'my message'
  return originalText;
};


export default encrypt;