import { db } from "../utils/firestore";
import {
  doc,
  getDoc,
  collection,
  addDoc,
  getDocs,
  updateDoc,
  // deleteDoc,
  query,
  orderBy,
  where,
  startAfter,
  endBefore,
  limit,
  setDoc,
  Timestamp,
  getCountFromServer,
} from "firebase/firestore";
// import { generateTrigam } from "../utils/trigamUtils";
// import { Timestamp } from "firebase/firestore";

//const dbCollection = db.collectionName("/contasgroups");

class GenericService {
  getOne = async (collectionName, id) => {
    try {
      if (id) {
        const noteSnapshot = await getDoc(doc(db, collectionName, id.toString()));
        if (noteSnapshot.exists()) {
          // console.log(collectionName, "id:", id, "=> noteSnapshot._document.version.timestamp.toDate()=", noteSnapshot._document.version.timestamp.toDate());
          return noteSnapshot.data();
        } else {
          throw new Error("Registro não encontrado!");
        }
      } else {
        return null;
      }
    } catch (error) {
      throw error;
    }
  };
  //usage: getNote(id);
  getOneSnapshot = async (collectionName, id) => {
    // //console.log("GenericService.getOneSnapshot(", collectionName, ",", id, ")");
    try {
      if (id) {
        const noteSnapshot = await getDoc(doc(db, collectionName, id.toString()));
        if (noteSnapshot.exists()) {
          // //console.log("noteSnapshot.data()=", noteSnapshot.data());
          // console.log(collectionName, "=> noteSnapshot=", noteSnapshot);
          // console.log(collectionName, "=> noteSnapshot._document=", noteSnapshot._document);
          // console.log(collectionName, "id:", id, "=> noteSnapshot._document.createTime.timestamp.toDate()=", noteSnapshot._document.createTime.timestamp.toDate());
          // console.log(collectionName, "id:", id, "=> noteSnapshot._document.readTime.timestamp.toDate()=", noteSnapshot._document.readTime.timestamp.toDate());
          // console.log(collectionName, "id:", id, "=> noteSnapshot._document.version.timestamp.toDate()=", noteSnapshot._document.version.timestamp.toDate());
          return noteSnapshot;
        } else {
          throw new Error("Registro não encontrado!");
        }
      } else {
        return null;
      }
    } catch (error) {
      throw error;
    }
  };
  //usage: getOneSnapshot(id);

  getAll = async (collectionName, orderby, ascDesc, showAllStates) => {
    // console.log("GenericService.getAll(", collectionName, orderby, ascDesc, ")");
    let querySnapshot = [];
    if (showAllStates) {
      querySnapshot = await getDocs(query(collection(db, collectionName), orderBy(orderby, ascDesc)));
    } else {
      querySnapshot = await getDocs(query(collection(db, collectionName), where("metadados.active", "==", true), orderBy(orderby, ascDesc)));
    }
    return querySnapshot.docs;
  };
  // getFilteredCollectionSizeByVinculo = async (
  //   collectionName,
  //   filterTarget,
  //   operation,
  //   filterValue,
  //   orderby,
  //   ascDesc,
  //   vinculoIdEntidade
  // ) => {
  //   //console.log("getFilteredCollection(", collectionName, filterTarget, operation, filterValue, orderby, ascDesc, vinculoIdEntidade, ")");
  //   if (operation === "==") {
  //     const querySnapshot = await getDocs(
  //       query(
  //         collection(db, collectionName),
  //         where("metadados.active", "==", true),
  //         where(filterTarget, operation, filterValue),
  //         where("vinculo.idEntidade", "==", vinculoIdEntidade)
  //       )
  //     );
  //     return querySnapshot.docs.length;
  //   } else {
  //     // //console.log("orderby = ", orderby);
  //     if (orderby !== "") {
  //       const querySnapshot = await getDocs(
  //         query(
  //           collection(db, collectionName),
  //           where("metadados.active", "==", true),
  //           where(filterTarget, operation, filterValue),
  //           orderBy(orderby, ascDesc)
  //         )
  //       );
  //       return querySnapshot.docs.length;
  //     } else {
  //       // //console.log("Passou por aqui!!!");
  //       const querySnapshot = await getDocs(
  //         query(collection(db, collectionName), where("metadados.active", "==", true), where(filterTarget, operation, filterValue))
  //       );
  //       // //console.log("querySnapshot=", querySnapshot);
  //       return querySnapshot.docs.length;
  //     }
  //   }
  // };

  // getCollectionSizeByVinculo = async (collectionName, vinculoIdEntidade) => {
  //   const querySnapshot = await getDocs(
  //     query(collection(db, collectionName), where("metadados.active", "==", true), where("vinculo.idEntidade", "==", vinculoIdEntidade))
  //   );
  //   return querySnapshot.docs.length;
  // };

  getCollectionSize = async (collectionName) => {
    // const querySnapshot = await getDocs(query(collection(db, collectionName), where("metadados.active", "==", true)));
    // return querySnapshot.docs.length;
    const q = query(collection(db, collectionName), where("metadados.active", "==", true));
    const snapshot = await getCountFromServer(q);
    //console.log("count: ", snapshot.data().count);
    return snapshot.data().count;
  };

  getCollectionSizeInactive = async (collectionName) => {
    // const querySnapshot = await getDocs(query(collection(db, collectionName), where("metadados.active", "==", true)));
    // return querySnapshot.docs.length;
    const q = query(collection(db, collectionName), where("metadados.active", "==", false), where("successorId", "==", ""));
    const snapshot = await getCountFromServer(q);
    //console.log("count: ", snapshot.data().count);
    return snapshot.data().count;
  };

  getCollectionSizeWithActive = async (collectionName, active_true_false, aditionalCondition) => {
    // const querySnapshot = await getDocs(query(collection(db, collectionName), where("metadados.active", "==", true)));
    // return querySnapshot.docs.length;
    let conditions = [];
    if (active_true_false !== undefined && active_true_false !== null && active_true_false !== "") conditions.push(where("metadados.active", "==", active_true_false));
    conditions.push(where("metadados.successorId", "==", ""));
    if (aditionalCondition !== undefined && aditionalCondition !== null) conditions.push(aditionalCondition);
    // console.log("conditions=", conditions);
    const snapshot = await getCountFromServer(query(collection(db, collectionName), ...conditions));
    // const q = query(collection(db, collectionName), where("metadados.successorId", "==", ""));
    // const snapshot = await getCountFromServer(q);
    // console.log("count: ", snapshot.data().count);
    return snapshot.data().count;
  };

  getCollectionSizeWithActiveVersionZero = async (collectionName, active_true_false, aditionalCondition) => {
    // const querySnapshot = await getDocs(query(collection(db, collectionName), where("metadados.active", "==", true)));
    // return querySnapshot.docs.length;
    let conditions = [];
    if (active_true_false !== undefined && active_true_false !== null && active_true_false !== "") conditions.push(where("metadados.active", "==", active_true_false));
    conditions.push(where("metadados.successorId", "==", ""));
    conditions.push(where("metadados.version", "in", [0, 1]));
    if (aditionalCondition !== undefined && aditionalCondition !== null) conditions.push(aditionalCondition);
    const snapshot = await getCountFromServer(query(collection(db, collectionName), ...conditions));
    // const q = query(collection(db, collectionName), where("metadados.successorId", "==", ""));
    // const snapshot = await getCountFromServer(q);
    // console.log("count: ", snapshot.data().count);
    return snapshot.data().count;
  };

  getFilteredCollectionSizeWithActive = async (collectionName, filterTarget, operation, filterValue, orderby, ascDesc, active_true_false, aditionalCondition) => {
    // console.log("getFilteredCollectionSizeWithActive(", collectionName, filterTarget, operation, filterValue, orderby, ascDesc, active_true_false, ")");
    try {
      let conditions = [];
      if (active_true_false !== undefined && active_true_false !== null && active_true_false !== "") conditions.push(where("metadados.active", "==", active_true_false));
      conditions.push(where(filterTarget, operation, filterValue));
      conditions.push(where("metadados.successorId", "==", ""));
      if (aditionalCondition !== undefined && aditionalCondition !== null) conditions.push(aditionalCondition);
      if (orderby !== undefined && orderby !== null && orderby !== "") {
        conditions.push(orderBy(orderby, ascDesc));
      }
      // console.log("conditions=", conditions);
      // const querySnapshot = await getDocs(query(collection(db, collectionName), ...conditions));
      const snapshot = await getCountFromServer(query(collection(db, collectionName), ...conditions));
      // console.log("count: ", snapshot.data().count);
      return snapshot.data().count;
    } catch (error) {
      throw error;
    }
  };

  getDoubleFilteredCollectionSizeWithActive = async (collectionName, filterTarget, operation, filterValue, filterTarget2nd, operation2nd, filterValue2nd, orderby, ascDesc, active_true_false, aditionalCondition) => {
    // console.log("getFilteredCollectionSizeWithActive(", collectionName, filterTarget, operation, filterValue, orderby, ascDesc, active_true_false, ")");
    try {
      let conditions = [];
      if (active_true_false !== undefined && active_true_false !== null && active_true_false !== "") conditions.push(where("metadados.active", "==", active_true_false));
      conditions.push(where(filterTarget, operation, filterValue));
      conditions.push(where(filterTarget2nd, operation2nd, filterValue2nd));
      conditions.push(where("metadados.successorId", "==", ""));
      if (aditionalCondition !== undefined && aditionalCondition !== null) conditions.push(aditionalCondition);
      if (orderby !== undefined && orderby !== null && orderby !== "") {
        conditions.push(orderBy(orderby, ascDesc));
      }
      console.log("conditions=", conditions);
      // const querySnapshot = await getDocs(query(collection(db, collectionName), ...conditions));
      const snapshot = await getCountFromServer(query(collection(db, collectionName), ...conditions));
      // console.log("count: ", snapshot.data().count);
      return snapshot.data().count;
    } catch (error) {
      console.log("error=", error);
      throw error;
    }
  };

  // getFilteredCollectionByVinculo = async (collectionName, filterTarget, operation, filterValue, orderby, ascDesc, vinculoIdEntidade) => {
  //   //console.log("getFilteredCollection(", collectionName, filterTarget, operation, filterValue, orderby, ascDesc, vinculoIdEntidade, ")");
  //   if (operation === "==") {
  //     const querySnapshot = await getDocs(
  //       query(
  //         collection(db, collectionName),
  //         where("metadados.active", "==", true),
  //         where(filterTarget, operation, filterValue),
  //         where("vinculo.idEntidade", "==", vinculoIdEntidade)
  //       )
  //     );
  //     return querySnapshot.docs;
  //   } else {
  //     // //console.log("orderby = ", orderby);
  //     if (orderby !== "") {
  //       const querySnapshot = await getDocs(
  //         query(
  //           collection(db, collectionName),
  //           where("metadados.active", "==", true),
  //           where(filterTarget, operation, filterValue),
  //           orderBy(orderby, ascDesc)
  //         )
  //       );
  //       return querySnapshot.docs;
  //     } else {
  //       // //console.log("Passou por aqui!!!");
  //       const querySnapshot = await getDocs(
  //         query(collection(db, collectionName), where("metadados.active", "==", true), where(filterTarget, operation, filterValue))
  //       );
  //       // //console.log("querySnapshot=", querySnapshot);
  //       return querySnapshot.docs;
  //     }
  //   }
  // };
  // getConditionsFromQueryFilter = (queryTarget, queryFilter) => {
  //   const conditions = [];
  //   if (typeof queryFilter === "object") {
  //     if (queryFilter !== undefined) {
  //       if (queryFilter.situacaoSearch !== "Todas") {
  //         conditions.push(where("situacao", "==", queryFilter.situacaoSearch));
  //       }
  //       if (queryTarget.includes("trigam")) {
  //         conditions.push(where(queryTarget, "array-contains-any", [...generateTrigam(queryFilter.searchInfo).slice(0, 10)]));
  //         if (queryFilter.searchInfo) {
  //           if (queryFilter.searchInfo.length > 0) conditions.push(orderBy(queryTarget, "asc"));
  //         }
  //       } else if (queryFilter.searchInfo.length > 0) {
  //         conditions.push(where(queryTarget, "==", queryFilter.searchInfo));
  //       }
  //     } else {
  //       // //console.log("queryFilter=", queryFilter);
  //     }
  //   } else if (typeof queryFilter === "string") {
  //     if (queryFilter.length > 0) {
  //       if (queryTarget.includes("trigam")) {
  //         conditions.push(where(queryTarget, "array-contains-any", [...generateTrigam(queryFilter)]));
  //         if (queryFilter.searchInfo) {
  //           if (queryFilter.searchInfo.length > 0) conditions.push(orderBy(queryTarget, "asc"));
  //         }
  //       } else conditions.push(where(queryTarget, "==", queryFilter));
  //     }
  //   }
  //   return conditions;
  // };

  getFilteredCollection = async (collectionName, filterTarget, operation, filterValue, orderby, ascDesc) => {
    // //console.log("getFilteredCollection(", collectionName, filterTarget, operation, filterValue, orderby, ascDesc, ")");
    let conditions = [];
    conditions.push(where("metadados.active", "==", true));
    conditions.push(where(filterTarget, operation, filterValue));
    if (orderby !== undefined && orderby !== null && orderby !== "") {
      conditions.push(orderBy(orderby, ascDesc));
    }
    // console.log("conditions=", conditions);
    const querySnapshot = await getDocs(query(collection(db, collectionName), ...conditions));
    return querySnapshot.docs;
  };


  getFilteredCollectionWithActive = async (collectionName, filterTarget, operation, filterValue, orderby, ascDesc, active_true_false) => {
    // //console.log("getFilteredCollection(", collectionName, filterTarget, operation, filterValue, orderby, ascDesc, active_true_false, ")");
    let conditions = [];
    if (active_true_false !== undefined && active_true_false !== null && active_true_false !== "") conditions.push(where("metadados.active", "==", active_true_false));
    conditions.push(where(filterTarget, operation, filterValue));
    if (orderby !== undefined && orderby !== null && orderby !== "") {
      conditions.push(orderBy(orderby, ascDesc));
    }
    const querySnapshot = await getDocs(query(collection(db, collectionName), ...conditions));
    return querySnapshot.docs;
  };

  getDoubleFilteredCollection = async (collectionName, filterTarget, operation, filterValue, filterTarget2, operation2, filterValue2, orderby, ascDesc) => {
    // console.log("getDoubleFilteredCollection(", collectionName, filterTarget, operation, filterValue, filterTarget2, operation2, filterValue2, orderby, ascDesc, ")");
    let conditions = [];
    conditions.push(where("metadados.active", "==", true));
    conditions.push(where(filterTarget, operation, filterValue));
    conditions.push(where(filterTarget2, operation2, filterValue2));
    if (orderby !== undefined && orderby !== null && orderby !== "") {
      conditions.push(orderBy(orderby, ascDesc));
    }
    // console.log("conditions=", conditions);
    const querySnapshot = await getDocs(query(collection(db, collectionName), ...conditions));
    return querySnapshot.docs;
  };

  // getCollectionSearchByPrefix = async (collectionName, queryTarget, queryText, orderby, ascDesc) => {
  //   // //console.log("getCollectionSearchByPrefix(", collectionName, queryTarget, queryText, orderby, ascDesc, ")");
  //   // //console.log(collectionName, queryTarget, queryText, orderby, ascDesc);

  //   //console.log(queryTarget, queryText);
  //   let conditions = [];
  //   if (queryText.length > 0) {
  //     if (["%", "*", "?"].includes(queryText.substring(0, 1))) {
  //       queryText = queryText.substring(1, queryText.length);
  //       queryText = queryText.trim().toLowerCase().replaceAll("  ", " ");
  //       conditions.push(where(queryTarget, "array-contains-any", [...generateTrigam(queryText).slice(0, 10)]));
  //       if (queryText.searchInfo) {
  //         if (queryText.searchInfo.length > 0) conditions.push(orderBy(queryTarget, "asc"));
  //       }
  //     } else {
  //       queryTarget = queryTarget.replace("trigam", "name");
  //       conditions.push(where(queryTarget, ">=", queryText));
  //       conditions.push(where(queryTarget, "<=", queryText + "\uf8ff"));
  //     }
  //   }

  //   const querySnapshot = await getDocs(
  //     query(collection(db, collectionName), where("metadados.active", "==", true), ...conditions, orderBy(orderby, ascDesc))
  //   );
  //   return querySnapshot.docs;
  // };

  getPrevPage = async (collectionName, firstVisible, pageSize, orderby, ascDesc, vinculo_idEntidade) => {
    // //console.log(
    //   "collectionName=",
    //   collectionName,
    //   " firstVisible=",
    //   firstVisible,
    //   " pageSize=",
    //   pageSize,
    //   " orderby=",
    //   orderby,
    //   " ascDesc=",
    //   ascDesc,
    //   " vinculo_idEntidade=",
    //   vinculo_idEntidade
    // );
    const conditions = [];
    if (vinculo_idEntidade) if (vinculo_idEntidade !== null) conditions.push(where("vinculo.idEntidade", "==", vinculo_idEntidade));
    conditions.push(where("metadados.active", "==", true));
    conditions.push(orderBy(orderby, ascDesc));
    if (pageSize !== null) conditions.push(limit(pageSize));
    if (firstVisible !== undefined && firstVisible !== null && firstVisible !== "") conditions.push(endBefore(firstVisible));
    let querySnapshot = await getDocs(query(collection(db, collectionName), ...conditions));
    return querySnapshot.docs;
  };

  getNextPage = async (collectionName, lastVisible, pageSize, orderby, ascDesc, vinculo_idEntidade) => {
    // //console.log(
    //   "collectionName=",
    //   collectionName,
    //   " lastVisible=",
    //   lastVisible,
    //   " pageSize=",
    //   pageSize,
    //   " orderby=",
    //   orderby,
    //   " ascDesc=",
    //   ascDesc,
    //   " vinculo_idEntidade=",
    //   vinculo_idEntidade
    // );
    const conditions = [];
    if (vinculo_idEntidade) if (vinculo_idEntidade !== null) conditions.push(where("vinculo.idEntidade", "==", vinculo_idEntidade));
    conditions.push(where("metadados.active", "==", true));
    conditions.push(orderBy(orderby, ascDesc));
    if (pageSize !== null) conditions.push(limit(pageSize));
    if (lastVisible !== undefined && lastVisible !== null && lastVisible !== "") conditions.push(startAfter(lastVisible));
    //console.log("conditions=", conditions);
    let querySnapshot = await getDocs(query(collection(db, collectionName), ...conditions));
    return querySnapshot.docs;
  };

  create = async (collectionName, value) => {
    try {
      // //console.log("GenericService.create(", collectionName, value, ")");
      return await addDoc(collection(db, collectionName), value)
        .then(async (docRef) => {
          return await getDoc(docRef)
            .then(async (doc) => {
              return doc;
            })
            .catch((error) => {
              throw error;
            });
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      throw error;
    }
  };

  createWithID = async (collectionName, UID, value) => {
    try {
      // console.log("GenericService.createWithID(", collectionName, UID, value, ")");
      if (isNaN(value.metadados.creation)) value.metadados.creation = Timestamp.fromDate(new Date());
      return setDoc(doc(db, collectionName, UID.toString()), value);
    } catch (error) {
      console.log("error=", error);
      throw error;
    }
  };

  createOrUpdate = async (collectionName, UID, value) => {
    try {
      return await this.getOne(collectionName, UID).then(async (retData) => {
        if (retData) {
          return await this.update(collectionName, UID, value);
        } else {
          return await this.createWithID(collectionName, UID, value);
        }
      }).catch((error) => {
        throw error;
      });
    } catch (error) {
      throw error;
    }
  };

  update = async (collectionName, id, value) => {
    console.log("GenericService.update(", collectionName, id, value, ")");
    try {
      return await this.getOne(collectionName, id)
        .then(async (retData) => {
          let oldVersion = {
            ...retData,
            metadados: {
              ...retData.metadados,
              active: false,
              successorId: id,
            },
          };
          console.log("oldVersion=", oldVersion, "value.metadados=", value.metadados);
          return await addDoc(collection(db, collectionName), oldVersion)
            .then(async (lastVersion) => {
              let ActualVersion = {
                ...value,
                metadados: {
                  ...value.metadados,
                  version: oldVersion.metadados.version + 1,
                  successorId: "",
                },
              };
              console.log("ActualVersion=", ActualVersion);
              return await updateDoc(doc(db, collectionName, id.toString()), ActualVersion)
                .then((docRef) => {
                  console.log("docRef=", docRef);
                  return docRef;
                })
                .catch((error) => {
                  throw error;
                });
            })
            .catch((error) => {
              throw error;
            });
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      throw error;
    }
  };


  updateReplacingData = async (collectionName, id, value) => {
    console.log("GenericService.update(", collectionName, id, value, ")");
    try {
      return await this.getOne(collectionName, id)
        .then(async (retData) => {
          let oldVersion = {
            ...retData,
            metadados: {
              ...retData.metadados,
              active: false,
              successorId: id,
            },
          };
          console.log("oldVersion=", oldVersion, "value.metadados=", value.metadados);
          return await addDoc(collection(db, collectionName), oldVersion)
            .then(async (lastVersion) => {
              let ActualVersion = {
                ...value,
                metadados: {
                  ...value.metadados,
                  version: value.metadados.version + 1,
                  successorId: "",
                },
              };
              console.log("collectionName=", collectionName, "id.toString()=", id.toString(), "ActualVersion=", ActualVersion);
              return await setDoc(doc(db, collectionName, id.toString()), ActualVersion)
                .then((docRef) => {
                  console.log("docRef=", docRef);
                  return docRef;
                })
                .catch((error) => {
                  throw error;
                });
            })
            .catch((error) => {
              throw error;
            });
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      throw error;
    }
  };

  updateWithoutVersioning = async (collectionName, id, value) => {
    // console.log("GenericService.update(", collectionName, id, value, ")");
    try {
      return await updateDoc(doc(db, collectionName, id.toString()), value)
        .then((docRef) => {
          // //console.log("docRef=", docRef);
          return docRef;
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      throw error;
    }
  };

  updateAndVersioning = async (collectionName, id, value) => {
    try {
      await updateDoc(doc(db, collectionName, id.toString()), { ["metadados.active"]: false });
      return await addDoc(collection(db, collectionName), value);
    } catch (error) {
      throw error;
    }
  };

  updateFederativeActiveState = async (collectionName, id, newStateValue, userId, userName) => {
    try {
      if (userId === undefined || userId === null) {
        return await updateDoc(doc(db, collectionName, id.toString()), { ["metadados.federativeactive"]: newStateValue }, { merge: true })
      } else {
        return await updateDoc(doc(db, collectionName, id.toString()), { ["metadados.federativeactive"]: newStateValue, ["metadados.ownerid"]: userId, ["metadados.owner"]: userName }, { merge: true })
      }
    } catch (error) {
      throw error;
    }
  };

  updateActiveState = async (collectionName, id, newStateValue, userId, userName) => {
    try {
      if (userId === undefined || userId === null) {
        return await updateDoc(doc(db, collectionName, id.toString()), { ["metadados.active"]: newStateValue }, { merge: true })
      } else {
        return await updateDoc(doc(db, collectionName, id.toString()), { ["metadados.active"]: newStateValue, ["metadados.ownerid"]: userId, ["metadados.owner"]: userName }, { merge: true })
      }
    } catch (error) {
      throw error;
    }
  };

  updateProperty = async (collectionName, id, propertyName, propertyValue) => {
    updateDoc(doc(db, collectionName, id), { [propertyName]: propertyValue }, { merge: true })
      .then((retValue) => {
        return retValue;
      }).catch((error) => {
        throw error;
      });
  };

  updateObject = async (collectionName, id, obj) => {
    updateDoc(doc(db, collectionName, id), obj, { merge: true })
      .then((retValue) => {
        return retValue;
      }).catch((error) => {
        throw error;
      });
  };

  updateObjectInsideCollection = async (collectionName, id, collectionObjectName, obj) => {
    // console.log("updateObjectInsideCollection(", collectionName, id, collectionObjectName, obj, ")");
    try {
      let oldCollection = await this.getOne(collectionName, id);
      if (oldCollection) {
        // console.log("oldCollection=", oldCollection);
        // console.log("collectionObjectName=", collectionObjectName);
        // console.log("oldCollection[collectionObjectName]=", oldCollection[collectionObjectName]);
        // console.log("[...oldCollection[collectionObjectName], obj]=", [...oldCollection[collectionObjectName], obj]);
        return await this.updateObject(collectionName, id, { [collectionObjectName]: [...oldCollection[collectionObjectName], obj] });
      }
    } catch (error) {
      throw error;
    }
  };

  deleteProperty = async (collectionName, id, propertyName) => {
    // console.log("deleteProperty(", collectionName, id, propertyName, ")");
    return await this.getOne(collectionName, id).then(async (retData) => {
      // let obj = retData;
      let properties = propertyName.split(".");
      // console.log("properties=", properties);
      // console.log("properties.length=", properties.length);
      if (properties.length === 1) {
        console.log("propertyName=", propertyName);
        delete retData[propertyName];
      } else if (properties.length === 2) {
        // console.log("propertyName[0]=", properties[0], "propertyName[1]=", properties[1]);
        delete retData[properties[0]][properties[1]];
      } else if (properties.length === 3) {
        delete retData[properties[0]][properties[1]][properties[2]];
      } else if (properties.length === 4) {
        delete retData[properties[0]][properties[1]][properties[2]][properties[3]];
      }
      return await updateDoc(doc(db, collectionName, id), retData)
        .then((retValue) => {
          return retValue;
        })
        .catch((error) => {
          throw error;
        });
    }).catch((error) => {
      throw error;
    });
  };

  delete = async (collectionName, id) => {
    // console.log("delete(", collectionName, id, ")");
    return await this.updateActiveState(collectionName, id, false).catch((error) => {
      throw error;
    });
  };
}

export default new GenericService();
