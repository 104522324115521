import React, { useEffect, useRef, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import VirtualCredencialComponent from "./VirtualCredencialComponent";
import { useAuth } from "../../../contexts/AuthContext";
import { getPortalUrlFromId } from "../../../utils/portaisUtil";
import { Link } from "react-router-dom";
import { orderBy, Timestamp, where } from "firebase/firestore";
import GenericVinculadoService from "../../../services/GenericVinculado.service";
import { Alert } from "react-bootstrap";
import { QrReader } from 'react-qr-reader';
import { decript } from "../../../utils/encriptUtil";
import GenericService from "../../../services/Generic.service";
import SmartSelect from "../../../components/SmartSelect";

import scannerBeepSound from "../../../assets/sounds/scanner-beep.mp3";

import "../../../styles/qrCodeScanner.css";
import { use } from "react";

const { forwardRef } = React;

const CredencialCheckPage = forwardRef((props, ref) => {
  const scannerBeep = new Audio(scannerBeepSound);
  const { domainDetails } = useAuth();
  const queryParams = new URLSearchParams(window.location.search);
  const targetPdfRef = useRef();
  const [uid, setUid] = useState("");
  const [pessoa, setPessoa] = useState(undefined);
  const [modalidadesInscrito, setModalidadesInscrito] = useState([]);
  const [checkPoints, setCheckPoints] = useState([]);
  const [error, setError] = useState(undefined);
  const [data, setData] = useState('No result');
  const [canShowValidacao, setCanShowValidacao] = useState(false);
  const [printOptions, setPrintOptions] = useState({
    marginLeft: 35,
    marginTop: 0,
    espacoEntreEtiquetasY: 1,
    espacoEntreEtiquetasX: 30,
    quantidadeCarteirinhasPorPagina: 5,
    espacoQuebraPagina: 69,
    arteCarteririnhaIdentificacaoFrente: "",
    arteCarteririnhaIdentificacaoVerso: "",
    imprimirVerso: false,
  });

  useEffect(() => {
    if (checkPoints.length > 0) return;
    GenericService.getOne("eventoscheckpoints", queryParams.get("ide"))
      .then((response) => {
        if (response) {
          setCheckPoints(response.locais);
        }
      }).catch((error) => {
        console.error("Erro ao buscar checkpoints: ", error);
      });
  }, [checkPoints]);

  const hideCredencial = () => {
    const modal = document.getElementById("credencialModal");
    if (modal) {
      if (modal.classList !== undefined && modal.classList !== null) {
        if (modal.classList.contains("show")) modal.classList.remove("show");
      }
      modal.setAttribute("aria-hidden", "true");
      modal.setAttribute("style", "display: none");
    }
  };

  const showCredencial = (uid) => {
    console.log("showCredencial(" + uid + ")");
    setTimeout(() => {
      const modal = document.getElementById("credencialModal");
      if (modal) {
        if (modal.classList !== undefined && modal.classList !== null) {
          if (!modal.classList.contains("show")) modal.classList.add("show");
        }
        modal.setAttribute("aria-hidden", "false");
        modal.setAttribute("style", "display: block");
      }
      setCanShowValidacao(true);

      let newData = {
        uid: uid,
        ide: queryParams.get("ide"),
        checkPoint: document.getElementById("checkPoint").value,
        dataHora: Timestamp.fromDate(new Date(new Date().toString() + new Date().getTime().toString() + " GMT-0300")),
        checkEvent: "checkIn",
      };
      GenericVinculadoService.getCollectionByConditions("eventoscheckpointsregister",
        [
          where("uid", "==", uid),
          where("ide", "==", queryParams.get("ide")),
          where("checkPoint", "==", document.getElementById("checkPoint").value),
          where("checkEvent", "==", "checkIn"),
          orderBy("dataHora", "desc"),
        ]).then((retData) => {
          if (retData[1] === 0) {
            GenericService.create("eventoscheckpointsregister", newData);
          } else {
            if (newData.dataHora - retData[0][0].data().dataHora > 5) {
              GenericService.create("eventoscheckpointsregister", newData);
            }
          }
        }).catch((error) => {
          console.error("Erro ao registrar checkPoint: ", error);
        });

      setTimeout(() => {
        setCanShowValidacao(false);
      }, 5000);
    }, 500);
  };

  const getDecriptedData = (data) => {
    let decryptedData = decript(data.text, process.env.REACT_APP_CREDENTIAL_KEY);
    return decryptedData;
  };

  const handlerOnResultQrCodeScan = async (result, error) => {
    if (!!result) {
      let qrCodeData = getDecriptedData(result);
      qrCodeData = JSON.parse(qrCodeData);
      let ide = queryParams.get("ide");
      let ScannedIDE = qrCodeData.ide;
      if (ide !== ScannedIDE) {
        setError("Credencial inválida para este evento.");
        return;
      }
      scannerBeep.play();
      // let localUID = qrCodeData.uid;
      console.log("qrCodeData=", qrCodeData);

      await GenericService.getOne("praticantes", qrCodeData.uid)
        .then(async (response) => {
          setUid(qrCodeData.uid);
          setPessoa(response);
          let modalidades = [];
          let conditions = [];
          conditions.push(where("idEvento", "==", ScannedIDE));
          conditions.push(where("uid", "==", qrCodeData.uid));
          conditions.push(where("metadados.active", "==", true));
          conditions.push(where("metadados.successorId", "==", ""));
          conditions.push(where("credenciado", "==", true));
          await GenericVinculadoService.getCollectionByConditions("inscritos", conditions).then(async (retData) => {
            if (retData) {
              retData[0].forEach((inscrito) => {
                if (inscrito.data().modalidade) {
                  modalidades.push(inscrito.data().modalidade);
                }
              });
              if (modalidades.length > 0) {
                setModalidadesInscrito(modalidades);
                setData("Acesso permitido");
                // console.log("Vai chamar showCredencial()");
                showCredencial(qrCodeData.uid);
              } else {
                setData("Acesso negado");
                setCanShowValidacao(true);
                setTimeout(() => {
                  setCanShowValidacao(false);
                }, 5000);
              }
            } else {
              setData("Acesso negado");
              setCanShowValidacao(true);
              setTimeout(() => {
                setCanShowValidacao(false);
              }, 5000);
            }
          }).catch((err) => {
            console.log(err);
          });

        }).catch((error) => {
          console.error("Erro ao buscar praticante: ", error);
        });

    }

    if (!!error) {
      // console.info(error);
    }
  };

  // if (props.dados === undefined) return (<div></div>);
  // else
  return (
    <>
      <div className="container-fluid padding-bottom-4px ms-2 me-2">
        <div className="container">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb mt-2 mb-2 fs-4">
              <li className="breadcrumb-item">
                <Link to={domainDetails ? getPortalUrlFromId(domainDetails.portalId) : "/"}>
                  <span className="material-icons text-muted" style={{ paddingTop: "6px" }}>
                    home
                  </span>
                </Link>
              </li>
              <li className="breadcrumb-item">
                <Link to={"/eventos/evento?id=" + queryParams.get("ide")} className="text-decoration-none text-muted">
                  Evento
                </Link>
              </li>
              <li className="breadcrumb-item active text-muted" aria-current="location">
                Credencial Check
              </li>
            </ol>
          </nav>
          <div className="mt-1">
            <div>
              <h6 className="font-weight-400 text-muted mb-0">Para obter sua credencial, informe seu CPF, sua data de nascimento e clique em [Exibir credencial]</h6>
              <h6 className="font-weight-400 text-muted mb-0">* Os campos com moldura azul são obrigatórios.</h6>
            </div>
            <div className="mt-3 w-100 row">
              <div className="col-auto"
                style={{ minWidth: "249px", maxWidth: "249px" }}
              >
                <div className="column"
                // style={{ padding: "64px 64px 64px 64px", maxWidth: "358px" }}
                >

                  <div className="mb-2 col">
                    <SmartSelect
                      id="checkPoint"
                      // value={data.dadosPessoais.estadoCivil}
                      // onChange={handleInputChange}
                      className="form-select g-input"
                      placeholder="Local da checagem"
                      required={true}
                    >
                      {checkPoints.map((checkPoint, index) => (
                        <option key={index} value={checkPoint}>{checkPoint}</option>
                      ))}
                    </SmartSelect>
                  </div>


                  <div>
                    {error && (
                      <Alert variant="danger" className="d-flex flex-nowrap mt-2">
                        <span className="material-icons-outlined me-2">error</span>
                        <div>{error}</div>
                      </Alert>
                    )}
                  </div>

                  {/* {console.log("data=", data)} */}
                  <div className="p-3 bg-black" style={{
                    minWidth: "233px", maxWidth: "233px",
                  }}>
                    <div className="qrCodeScannerBorder p-3 bg-black" style={{
                      minWidth: "200px", maxWidth: "200px",
                    }}>
                      <QrReader
                        onResult={handlerOnResultQrCodeScan}
                        style={{ width: "100%" }}
                      // style={{ width: '100px' }}
                      />
                    </div>
                  </div>
                  {canShowValidacao && (
                    data === "Acesso permitido" ? (
                      <div className="d-flex justify-content-center">
                        <p className="mt-3 col-auto badge text-bg-success" style={{ scale: "2.0" }}>{data}</p>
                      </div>
                    ) : (
                      <div className="d-flex justify-content-center">
                        <p className="mt-3 col-auto badge text-bg-danger" style={{ scale: "2.0" }}>{data}</p>
                      </div>
                    )
                  )}

                </div>
              </div>
              {/* <div className="modal-dialog"> */}
              <div className="d-flex justify-content-start col" style={{ scale: "0.65" }}>
                {canShowValidacao && data === "Acesso permitido" && (
                  <VirtualCredencialComponent dados={props.dados} printOptions={printOptions} uid={uid} pessoa={pessoa} modalidadesInscrito={modalidadesInscrito} targetPdfRef={targetPdfRef} hideThis={hideCredencial} noModal={true} compact={true} hideClose={true} />
                )}
              </div>
              {/* </div> */}



            </div>
          </div>
        </div>
      </div>




    </>
  );
});
export default CredencialCheckPage;
